import React from 'react';
import { Controller } from 'react-hook-form';

import {
  MultiSelect,
  MultiSelectOptionalProps,
  MultiSelectValueType
} from '../../MultiSelect';

import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from './MultiSelectField.types';

function MultiSelectField<T>({
  classNamePrefix = null,
  closeMenuOnSelect = true,
  control,
  data = [],
  defaultValue = null,
  disabled = false,
  emptyValue = null,
  i18nLabel = null,
  i18nPlaceholder,
  inputWrapperClassName = null,
  isClearable = false,
  isSearchable = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  multi = false,
  name,
  onInputChange,
  optionsLoading = false,
  placeholder = null,
  onMenuScrollToBottom,
  isLoading = false,
  onChange,
  onBlur,
  error
}: MultiSelectFieldControlProps<T> &
  MultiSelectFieldProps<T> &
  MultiSelectOptionalProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: onMultiSelectChange, value, name },
        fieldState: { error: fieldError }
      }) => (
        <MultiSelect
          classNamePrefix={classNamePrefix}
          closeMenuOnSelect={closeMenuOnSelect}
          data={data}
          defaultValue={defaultValue}
          disabled={disabled}
          emptyValue={emptyValue}
          error={fieldError?.message || error}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          inputWrapperClassName={inputWrapperClassName}
          isClearable={isClearable}
          isSearchable={isSearchable}
          labelClassName={labelClassName}
          menuIsOpen={menuIsOpen}
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
          multi={multi}
          onChange={(newValue, actionMeta) => {
            onMultiSelectChange(newValue, actionMeta);
            onChange?.(newValue);
          }}
          onInputChange={onInputChange}
          optionsLoading={optionsLoading}
          placeholder={placeholder}
          value={value as MultiSelectValueType}
          name={name}
          onMenuScrollToBottom={onMenuScrollToBottom}
          isLoading={isLoading}
          onBlur={onBlur}
        />
      )}
    />
  );
}

export default MultiSelectField;
