import React, { ReactNode } from 'react';

import { LightboxItem } from '../../LightboxWrapper.types';

import { LightboxWrapperOpenInNewTabButton } from '../LightboxWrapperOpenInNewTabButton';
import { LightboxWrapperImageEditButton } from '../LightboxWrapperImageEditButton';

import { TooltipSingletonSourceWrapper } from '../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';

export interface LightboxWrapperBottomContentProps {
  customButtons?: ReactNode;
  imageItem: LightboxItem;
  withFullScreenButton?: boolean;
  withImageEditButton?: boolean;
  onImageEdit: () => void;
}

function LightboxWrapperBottomContent({
  customButtons,
  imageItem,
  withFullScreenButton,
  withImageEditButton,
  onImageEdit
}: LightboxWrapperBottomContentProps) {
  return (
    <div className="flex">
      <div className="flex-1 p-3 text-left"></div>
      <div className="flex-1 p-3 text-center">
        <div className="flex items-center justify-center space-x-0.5 mx-auto">
          <TooltipSingletonSourceWrapper
            placement={TooltipPlacement.TOP}
            withArrow
          >
            {customButtons}
            {withImageEditButton ? (
              <LightboxWrapperImageEditButton onImageEdit={onImageEdit} />
            ) : null}
            {withFullScreenButton ? (
              <LightboxWrapperOpenInNewTabButton file={imageItem.file} />
            ) : null}
          </TooltipSingletonSourceWrapper>
        </div>
      </div>
      <div className="flex-1 p-3 text-right"></div>
    </div>
  );
}

export default LightboxWrapperBottomContent;
